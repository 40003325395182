import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import {
    AddBox, ArrowUpward, Check, ChevronLeft,
    ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage,
    LastPage, Remove, SaveAlt, Search, ViewColumn
} from '@material-ui/icons';

import { usersNicsService } from '../../connections/feathers';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default class UsersList extends Component {
    state = {
        columns: [
            { title: 'ID', field: 'id', editable: 'never' },
            { title: 'Nombre', field: 'user.first_name', editable: 'never' },
            { title: 'Apellido', field: 'user.last_name', editable: 'never' },
            { title: 'Nic', field: 'nic' }
        ],
        usersNics: []
    }


    async componentDidMount() {
        this.fetchUsersNics()
    }

    fetchUsersNics = async () => {
        const usersNics = await usersNicsService.find({ query: { user_id: this.props.id }, paginate: false })
            .then(it => it.data)
        this.setState({ usersNics, })
    }

    addUserNic = async (newData) => {
        await usersNicsService.create({ user_id: this.props.id, nic: newData.nic })
        this.fetchUsersNics()
    }

    deleteUserNic = async (oldData) => {
        await usersNicsService.remove(oldData.id, {})
        this.fetchUsersNics()
    }

    editUserNic = async (oldData, newData) => {
        await usersNicsService.patch(newData.id, { nic: newData.nic })
        this.fetchUsersNics()
    }

    render() {
        return (
            <MaterialTable
                icons={tableIcons}
                title="Nic asociados al usuario"
                columns={this.state.columns}
                data={this.state.usersNics}
                editable={{
                    onRowAdd: newData => this.addUserNic(newData),
                    onRowUpdate: (newData, oldData) => this.editUserNic(oldData, newData),
                    onRowDelete: oldData => this.deleteUserNic(oldData),
                }}
            />
        )
    }
};