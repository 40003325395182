import UsersNicsList from './lists';
import UsersNicsEdit from './edit';
import UserNicsIcon from '@material-ui/icons/Dehaze';
import UsersNicsCreate from './create';
import UserNicsShow from './show';
import React from 'react';

const UserNicsTitle = ({ record }) => {
    return <span>Editando el nic {record ? `"${record.nic} "` : ''}</span>;
};

export { UsersNicsList, UserNicsIcon, UsersNicsCreate, UsersNicsEdit, UserNicsShow, UserNicsTitle };