import React, { Component } from "react";
import { Dehaze } from "@material-ui/icons/";
import { exportUserDataService } from "../../connections/feathers";

import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ShowButton,
  Filter,
  TextInput,
  CardActions,
  CreateButton,
} from "react-admin";
import { Button } from "@material-ui/core";

const ProjectsActions = ({ basePath }) => {
  const onButtonUserExcel = async () => {
    const url = await exportUserDataService
      .create({})
      .then((it) => it.link)
      .catch((error) => console.log(error));

    window.open(url, "_blank");
  };

  return (
    <CardActions>
      <CreateButton basePath={basePath} />

      <Button
        color="primary"
        className="paddingHeight"
        onClick={() => onButtonUserExcel()}
      >
        Descargar Excel
      </Button>
    </CardActions>
  );
};

const FullNameField = ({ record }) => {
  return record ? `${record.first_name} ${record.last_name}` : "";
};

FullNameField.defaultProps = { label: "Nombres" };

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscador" source="name" alwaysOn />
  </Filter>
);

export default class UsersList extends Component {
  render() {
    return (
      <List
        {...this.props}
        filters={<UserFilter />}
        actions={
          <ProjectsActions
          // sendFile={this.sendFile}
          // loading={this.loading}
          // loadingShow={this.state.loading}
          />
        }
        title="Usuarios"
      >
        <Datagrid>
          <TextField label="Id" source="id" title="Listado de usuarios" />
          <FullNameField />
          <TextField source="email" label="Correo" />
          <TextField source="company" label="Empresa" />
          <TextField source="role" label="Rol" />
          <EditButton label="Editar" />
          <ShowButton label="Nics" icon={<Dehaze />} />
          <DeleteButton label="Eliminar" />
        </Datagrid>
      </List>
    );
  }
}
