import feathers from "@feathersjs/feathers";
import restFeathers from "@feathersjs/rest-client";
import feathersAuthClient from "@feathersjs/authentication-client";

//const host = 'http://localhost:3030';
const host = "https://telemedios-back.energiacaribesol.co";

const appFeathers = feathers();
const restClient = restFeathers(host);
const authClient = feathersAuthClient({
  header: "Authorization",
  path: "/authentication",
  jwtStrategy: "jwt",
  entity: "user",
  service: "users",
  storage: window.localStorage,
});

appFeathers.configure(restClient.fetch(window.fetch.bind(window)));
appFeathers.configure(authClient);
export const usersNicsService = appFeathers.service("users-nic");
export const usersService = appFeathers.service("users");
export const exportUserDataService = appFeathers.service("export-user-data");

export default appFeathers;

export const rest = appFeathers;
