import React from 'react';
import { Admin, Resource } from 'react-admin';
import { restClient, authClient } from 'ra-data-feathers';
import feathers from './connections/feathers';
import { Layout } from './components/layout';
import authProvider from './authProvider';
import { UsersList, UsersEdit, UsersCreate, UserShow } from './components/users';
import { UserNicsShow, UsersNicsCreate, UsersNicsList, UsersNicsEdit } from './components/users-nics';

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { strategy: 'local' },
  permissionsKey: 'permissions',
  permissionsField: 'roles',
  redirectTo: '/login',
};

const options = { usePatch: true };


const App = () => ([
  <Admin
    authProvider={authProvider}
    dataProvider={restClient(feathers, options)}
    appLayout={Layout}
  // loginPage={Login}
  // dashboard={dashboard}
  >
    <Resource name="users" list={UsersList} edit={UsersEdit} show={UserShow} create={UsersCreate} />
    <Resource name="users-nic" list={UsersNicsList} create={UsersNicsCreate} edit={UsersNicsEdit} />
  </Admin>
]
);

export default App;
//