import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubMenu from './subMenu';
import { UserIcon } from '../users';
import { UserNicsIcon } from '../users-nics';

import {
    MenuItemLink,
    Responsive,
    DashboardMenuItem
} from 'react-admin';
import { from } from 'rxjs';

export default class Menu extends Component {
    state = {
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, logout } = this.props;
        return (
            <div>
                {/* <DashboardMenuItem onClick={onMenuClick} /> */}
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
                <MenuItemLink
                    className={"menuItems"}
                    to={`/users`}
                    primaryText={'Usuarios'}
                    onClick={onMenuClick}
                    leftIcon={<UserIcon />}
                />
                <MenuItemLink
                    className={"menuItems"}
                    to={`/users-nic`}
                    primaryText={'Nic de usuarios'}
                    onClick={onMenuClick}
                    leftIcon={<UserNicsIcon /* color='primary' */ />}
                />
            </div>
        );
    }
}