import React, { Component, Fragment } from 'react';
import { usersService, usersNicsService } from '../../connections/feathers';
import { TextField, Button, Paper, Grid } from '@material-ui/core';
import Select from 'react-select';
import { Link } from 'react-router-dom';

export default class UsersCreate extends Component {

    state = {
        users: [
            { value: '', label: '' }
        ],
        user_id: null
    }

    componentDidMount() {
        this.fetchUsers()
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
        console.log(this.state, '------------')
    }

    fetchUsers = async () => {
        const users = await usersService.find({ paginate: false })
            .then(it => it.data)
        this.setState({ users: users.map((it) => ({ value: it.id, label: it.email })) })
    }

    saveUserNics = async () => {
        const userNic = await usersNicsService.create({ user_id: this.state.user_id, nic: this.state.nic })

        this.props.history.push(`/users-nic/${userNic.id}`)
    }

    render() {
        return (
            <Fragment>
                <Paper>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Select
                                className="margin-left-20 margin-top-20"
                                classNamePrefix="select"
                                defaultValue=""
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={this.state.users}
                                onChange={e => {
                                    if (!e) return;
                                    this.setState({ user_id: e.value })
                                }}
                            />
                            <TextField
                                style={{ width: '100%' }}
                                className="margin-left-20 margin-top-20"
                                id="standard-name"
                                label="Nic"
                                name="nic"
                                value={this.state.nic}
                                onChange={(e) => this.handleChange(e)}
                                margin="normal"
                            />
                            <Button
                                className="margin-left-20 margin-top-20 margin-button-20"
                                variant="contained"
                                color="primary"
                                onClick={() => this.saveUserNics()}
                            >
                                Guardar
                        </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment>
        )
    }
};