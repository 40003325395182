// in src/authProvider.js
import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import { rest } from './connections/feathers';

// import decodeJwt from 'jwt-decode';
const login = (email, password) => rest.authenticate({ strategy: 'local', email, password });
const current = () => rest.service('current-user').find();

const saveSession = async ({ user, accessToken }) => {
    localStorage.setItem('feathers-jwt', accessToken);
    localStorage.setItem('last_session', `${user.id}`);
    localStorage.setItem(`session`, JSON.stringify({ user, token: accessToken }));

    return user;
};

export default async (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        return login(username, password)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({ accessToken }) => {
                return current().then(user => {
                    saveSession({ user, accessToken });
                    return user;
                });
            });
    } else if (type === AUTH_LOGOUT) {
        localStorage.clear();
        return Promise.resolve();
    } else if (type === AUTH_ERROR) {
        // ...
    } else if (type === AUTH_CHECK) {
        return localStorage.getItem('session') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
    } else if (type === AUTH_GET_PERMISSIONS) {
        const { user } = JSON.parse(localStorage.getItem('session'));
        return user.role == 'Admin' ? Promise.resolve(user) : new Promise((resolve, reject) => { localStorage.clear(); reject() });
    }
    return Promise.reject('Unknown method');
};
