import React, { Component } from 'react';
import {
    Edit, SimpleForm, DisabledInput, TextInput, SelectInput
} from 'react-admin';
import { UserNicsTitle } from './index';
import { usersService, usersNicsService } from '../../connections/feathers';
import { TextField, Grid } from '@material-ui/core';

export default class UsersNicEdit extends Component {

    state = {
        user: {}
    }


    componentDidMount() {
        this.fetchUser()
    }

    fetchUser = async () => {
        const usersNics = await usersNicsService.get(this.props.id)
        const user = await usersService.get(usersNics.user_id)
        this.setState({ user })
    }

    render() {
        const { user } = this.state;

        return (
            <Edit title={<UserNicsTitle />} {...this.props}>
                <SimpleForm>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <DisabledInput label="Id" source="id" />
                            {/* <ReferenceInput label="Author" source="author_id" reference="authors">
                                <SelectInput optionText="last_name" />
                            </ReferenceInput> */}
                            <TextField
                                label="Nombre del usuario"
                                value={`${user.first_name} ${user.last_name}`}
                                margin="normal"
                                disabled
                            />
                            <TextField
                                label="Empresa"
                                value={`${user.company}`}
                                margin="normal"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Correo"
                                value={`${user.email}`}
                                margin="normal"
                                disabled
                            />
                            <TextInput source="nic" label="Nic" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
};