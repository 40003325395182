import React, { Component } from 'react';
import {
    TextField, Show, SimpleShowLayout
} from 'react-admin';
import { UserTitle } from './index';

export default class UsersShow extends Component {
    render() {
        return (
            <Show {...this.props} title={''} >
                <SimpleShowLayout>
                    <TextField source="title" />
                    <TextField source="teaser" />
                </SimpleShowLayout>
            </Show>
        )
    }
};