import React, { Component } from 'react';
import { Create, SimpleForm, SelectInput, TextInput, ReferenceInput, DisabledInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';

const choices = [
    { id: true, description: 'Activo' },
    { id: false, description: 'Inactivo' }
];

export default class UsersCreate extends Component {
    render() {
        return (
            <Create title="Crear usuario" {...this.props}>
                <SimpleForm>
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <TextInput source="first_name" label="Nombres" />
                            <TextInput source="last_name" label="Apellidos" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput source="email" label="Correo" />
                            <TextInput source="password" label="Contraseña" type="password" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput source="company" label="Empresa" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        )
    }
};