import UsersList from './lists';
import UsersEdit from './edit';
import UserIcon from '@material-ui/icons/Person';
import UsersCreate from './create';
import UserShow from './show';
import React from 'react';

const UserTitle = ({ record }) => {
    return <span>Usuario {record ? `"${record.first_name}  ${record.last_name}"` : ''}</span>;
};


export { UsersList, UserIcon, UsersCreate, UsersEdit, UserShow, UserTitle };