import React, { Component } from 'react';
import { Dehaze } from '@material-ui/icons/';
import {
    List, Datagrid, TextField,
    EditButton, DeleteButton, ShowButton, ReferenceField, Filter, TextInput
} from 'react-admin';

const FullNameField = ({ record }) => {
    return record ? `${record.first_name} ${record.last_name}` : ''
}

const UsersNicsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nic" source="nic" alwaysOn />
    </Filter>
);

export default class UsersNicsList extends Component {
    render() {
        return (
            <List {...this.props} title="Nics de usuarios"
                exporter={false}
                filters={<UsersNicsFilter />}>
                <Datagrid>
                    <TextField label="Id" source="id" />
                    <ReferenceField label="Usuario" source="user_id" reference="users">
                        <FullNameField />
                    </ReferenceField>
                    <ReferenceField label="Empresa" source="user_id" reference="users">
                        <TextField source="company" />
                    </ReferenceField>
                    <TextField label="Nic" source="nic" />
                    <EditButton label="Editar" />
                    {/* <ShowButton label="Nics" icon={<Dehaze />} /> */}
                    <DeleteButton label="Eliminar" />
                </Datagrid>
            </List>
        )
    }
};